html, body {
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  min-height: 100% !important;
}

#app {
  min-height: 100% !important;
  display: flex;
  flex-direction: column;
}

#map {
  width: 100%;
  height: 450px;
  background-color: #eeeeee;
  resize: vertical;
}

textarea {
  resize: none !important;
  font-size: 0.7em !important;
}

code {
  font-size: 0.7em !important;
  color: #0f5132 !important;
}

.toast, .toast-body {
  color: #155724;
  background-color: #d4edda;
  border: 0 !important;
  border-radius: 5px;
}

#map::-webkit-resizer {
  background-color: "none";
  border-bottom: 4px double black;
  padding-right: 20px;
}
